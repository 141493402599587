import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { ContactService } from '../../../services/contact.service';
import { SelectItemModel } from '../../../common/models/select-item.model';
import { ContactQueryModel } from '../../../common/models/contact-us-query-model';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LoaderService } from '../../../services/loader.service';
import { isPlatformBrowser } from '@angular/common';
import * as FormData from 'form-data';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  vendorDetails: OnlineStoreModel
  googleMapUrl: string
  subjectList: Array<SelectItemModel> = new Array<SelectItemModel>();
  selectedFile: File;
  formData = new FormData();
  contactForm: FormGroup
  emailRegex = Constants.REGX.emailRegex;
  mobileRegex = Constants.REGX.mobileRegex;
  currUser;
  currUser_email;
  currUser_name;
  currUser_mobile;

  constructor(
    private contactService: ContactService,
    public formBuilder: FormBuilder,
    public dialogService: LsDialogService<OkayMessageComponent>,
    private loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
      phoneNum: ["", [Validators.required, Validators.pattern(this.mobileRegex)]],
      message: ["", Validators.required],
      queryOption: [-1, Validators.required]
    })
    this.vendorDetails = Constants.VENDOR_DETAILS;
    this.fetchContactMap();
    this.populateComboData();
    this.getCurrUser();
  }

  getCurrUser() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.currUser.email) {
          this.currUser_email = this.currUser.email;
          this.contactForm.patchValue({
            'email': this.currUser_email
          });
        }
        if (this.currUser.mobile) {
          this.currUser_mobile = this.currUser.mobile;
          this.contactForm.patchValue({
            'phoneNum': this.currUser_mobile
          });
        }
        if (this.currUser) {
          if (this.currUser.fName)
            this.currUser_name = this.currUser.fName
          if (this.currUser.fName && this.currUser.lName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.lName
          if (this.currUser.fName && this.currUser.mName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.mName
          if (this.currUser.fName && this.currUser.mName && this.currUser.lName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.mName + ' ' + this.currUser.lName
        } else if (!this.currUser_name && this.currUser.userName) {
          this.currUser_name = this.currUser.userName;
        }
        if(this.currUser_name){
          this.contactForm.patchValue({
            'name': this.currUser_name
          });
        }
      }
    }
  }

  populateComboData() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select a Subject---');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(1, 'Order Related');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(2, 'Shipment');
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(3, 'My Account')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(4, 'Payment Related')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(5, 'Report An Error')
    this.subjectList.push(selectItem);
    selectItem = new SelectItemModel();
    selectItem.SelectItem(6, 'Others')
    this.subjectList.push(selectItem);
  }

  fetchContactMap() {
    let data = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.generalSettingsDTO
    if (data) {
      if (data.addressGoogleMapURL != undefined) {
        this.googleMapUrl = data.addressGoogleMapURL;
      }
    } else {
      this.contactService.getMap(resp => {
        if (resp.data != undefined && resp.data.addressGoogleMapURL != undefined) {
          this.googleMapUrl = resp.data.addressGoogleMapURL;
        }
      })
    }
  }

  onFileChange(event, fileName, changeMenu) {
    this.selectedFile = event.target.files[0];
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
      fileName.innerHTML = this.selectedFile.name;
      if (!changeMenu.className.includes('changeLnk')) {
        changeMenu.innerHTML = "Change";
        changeMenu.className = "attchment changeLnk";
      }
    }
  }

  submitContactQuery() {
    let queryModel: ContactQueryModel = new ContactQueryModel();
    let fileName: Array<string> = new Array<string>();
    if (this.selectedFile != undefined) {
      fileName.push(this.selectedFile.name);
    }
    this.showLoader();
    // if (this.currUser) {
    //   if (this.currUser_email)
    //     this.contactForm.patchValue({
    //       'email': this.currUser_email
    //     });
    //   if (this.currUser_name)
    //     this.contactForm.patchValue({
    //       'name': this.currUser_name
    //     });
    //   if (this.currUser_mobile)
    //     this.contactForm.patchValue({
    //       'phoneNum': this.currUser_mobile
    //     });
    // }
    queryModel.name = this.contactForm.value.name;
    queryModel.email = this.contactForm.value.email;
    queryModel.mobileNo = this.contactForm.value.phoneNum;
    queryModel.desc = this.contactForm.value.message;
    queryModel.queryStatus = this.contactForm.value.queryOption;
    queryModel.attachments = fileName;
    queryModel.queryType = 2;
    // if(this.currUser){
    //   if(this.currUser_email)
    //     queryModel.email = this.currUser_email;
    //   if(this.currUser_name)
    //     queryModel.name = this.currUser_name;
    //   if(this.currUser_mobile)
    //     queryModel.mobileNo = this.currUser_mobile;
    // }
    this.formData.append("file", this.selectedFile);
    this.formData.append("data", JSON.stringify(queryModel));
    this.contactService.sendContactQuery(this.formData, resp => {
      this.hideLoader();
      this.contactForm.reset();
      this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
      })
    })

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  private showLoader() {
    this.loaderService.show();
  }

  private hideLoader() {
    this.loaderService.hide();
  }

}
