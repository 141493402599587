<header *ngIf="!checkOutVisibility">
  <!-- <mat-sidenav-container class="">
        <mat-sidenav ngClass="menu-width" ngClass.xs="menu-width-xs" #accountDashboard opened="false" mode="over">
            <app-category></app-category>
        </mat-sidenav> -->
  <div fxLayout="column" class="header-column desktop-layout">
    <div fxLayout="row" class="header-top defaultWidth" fxShow.gt-xs fxHide.lt-sm>
      <div fxLayout="row" fxFlex="17" fxFlexAlign="start" style="position:relative; height:100%">
        <a [routerLink]="''" style="height:100%; width:100%; position:relative;" *ngIf="companyDetails">
            <img [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath " style="padding-left: 20px;" onError="this.src = 'assets/images/defaultLogo.png'" title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">            
        </a>
      </div>
      <div fxLayout="column" fxFlex="83" fxFlexAlign="end" style="height:100%" fxLayoutAlign="end end" class="heading-tag">
        <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p>
        <div fxLayout="row" fxFlex="100" class="search-panel">
          <div *ngIf="currencyList != undefined" fxLayout="column" class="currency-combo">
            <div class="selected-div" (click)="showCurrencyList(currencyOption)">
              <span [id]="currencyList[selectedCurrencyIndex]?.currencyCode" class="currency-span"></span>
              <a class="currency-value"> {{currencyList[selectedCurrencyIndex]?.currencyCode}}</a>
            </div>
            <ul class="currency-option" #currencyOption>
              <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
                <span [id]="model.currencyCode" class="currency-span"></span>
                <a class="currency-value">{{model?.currencyCode}}</a>
              </li>
            </ul>
          </div>
          <app-search></app-search>
        </div>
      </div>
    </div>
    <div fxLayout="row" class="header-bottom">
      <div class="defaultWidth">
        <div class="category">
          <p class="categories" style="margin:0" fxShow.gt-xs fxHide.lt-sm> Categories</p>
          <div class="fetch-categories">
            <ul *ngIf="categoryList != undefined">
              <li *ngFor="let category of categoryList">
                <div id="itrCat-0">
                  <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                    <h1>{{category?.categoryName}}</h1>
                    <!-- <span *ngIf="category.categoryModels != undefined" class="mob-arrow-down fa fa-lg fa-angle-down"></span> -->
                  </a>
                  <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                    <li *ngFor="let subCat of category?.categoryModels">
                      <div id="itrCat-1">
                        <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                          style="position:relative">
                              {{subCat?.categoryName}}
                              <span *ngIf="subCat?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right" style="position: absolute;right: 9px;font-weight: bold;"></span>
                        </a>
                        <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                          <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                            <div id="itrCat-2">
                              <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{secLevel?.categoryURL}}">
                                    {{secLevel?.categoryName}}
                                  </a>
                              <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                                <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                  <div id="itrCat-3">
                                    <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{thirdLevel?.categoryURL}}">
                                          {{thirdLevel?.categoryName}}
                                        </a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div fxFlexAlign="start" fxLayout="row" fxFlex="65" class="menu-links" fxShow.gt-xs fxHide.lt-sm>
          <a routerLink="partners" routerLinkActive="active">Partners In Prosperity</a>
          <a routerLink="blog" routerLinkActive="active">Blog</a>
          <a *ngIf="exhibitionPresence" href={{exhibitionLnk}} target="_blank">Exhibition Update</a>
          <a *ngIf="aboutUsPresence" routerLink={{aboutUsLink}}>About Us</a>
          <a *ngIf="contactUsPresence" routerLink={{contactLnk}}>Contact Us</a>
        </div>
        <div fxLayoutAlign="end start" fxLayout="row" fxFlex="35" class="user-links" style="height:100%" fxShow.gt-xs fxHide.lt-sm>
          <!-- To Be Hidden when user not logged in  -->
          <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
            <p id="lblHi" class="lblUserName">
              Welcome
            </p>
            <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? userState.userName : userState.fName + '!'"></p>
          </div>
          <div *ngIf="loggedIn" id="pnlMyAccount" class="pnlMyAccount">
            <p id="lblMyAccnt" class="lblMyAccnt"> My Account </p>
            <div id="dashOptionPnl" tabindex="1">
              <a routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
              <a routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
              <a routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Log</a>
              <a routerLink="my-dashboard/change-password" routerLinkActive="active">Change Password</a>
              <a *ngIf="allowWishlist" routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist</a>
              <a *ngIf="allowEnquiry" routerLink="my-dashboard/enquiries" routerLinkActive="active">My Enquiries</a>
              <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a>
            </div>
          </div>
          <p *ngIf="!loggedIn" class="login" (click)="onOpen()">Sign In</p>
          <p *ngIf="!loggedIn && !guestLoggedIn" class="track-order" (click)="showTrackPopOver()">Track Order</p>
          <!-- <p class="login" (click)="openDialog()">Sign In</p> -->
          <!-- <p class="track-order">Track Order</p> -->
          <div fxLayout="row" class="cart" (click)="openCart()" [ngClass]="{'hide': !allowCart}">
            <span class="fa fa-lg fa-shopping-cart" style="font-family:fontawesome"></span>
            <p class="cart-count">({{cartCount}})</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxShow.lt-sm fxHide.gt-xs fxLayout="column" style="background-color:#f5f5f5;" class="mobile-layout">
    <div class="tagline" fxLayout="column" fxFlex="100">
      <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p>
    </div>
    <div fxLayout="row" style="border-top:5px solid #04582d; height:70px;">
      <div fxFlex="14" class="nav-panel" (click)="showCategories()"><span class="nav-bar"> </span></div>
      <div fxFlex="28" style="height:100%" class="img-logo" style="position:relative" (click)="navigateToHome()">
        <img src="{{companyDetails?.storeLogo}}" onerror="uploadDefaultLogoImage($event)" title="{{companyDetails?.storeName}}" alt="{{companyDetails.storeName}}"
          style="max-height:100%; max-width:100%;">
      </div>

      <div class="user-info" fxFlex="57" fxLayout="row" fxLayoutAlign="end end">
        <!-- <div *ngIf="currencyList != undefined" fxLayout="column" fxFlex="40" class="currency-combo">
          <div class="selected-div" (click)="showCurrencyList(currencyOption)">
            <span [id]="currencyList[selectedCurrencyIndex].currencyCode" class="currency-span"></span>
            <a class="currency-value"> {{currencyList[selectedCurrencyIndex].currencyCode}}</a>
          </div>
          <ul class="currency-option" #currencyOption>
            <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
              <span [id]="model.currencyCode" class="currency-span"></span>
              <a class="currency-value">{{model.currencyCode}}</a>
            </li>
          </ul>
        </div>
        <div class="login-panel" fxFlex="20"><span class="login-mob" (click)="openLoginDialog()"></span></div> -->
        <div class="track-panel" fxFlex="20" (click)="showTrackPopOver()"><span class="track-mob"></span></div>
        <div class="cart-panel" fxFlex="20" (click)="openCart()"><span class="cart-mob"></span><span class="cart-count">{{cartCount}}</span></div>
        <!-- <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails.storeTagLine}}</p> -->
      </div>
    </div>
    <div fxLayout="row" class="search-panel">
      <app-search></app-search>
    </div>
  </div>
  <!-- </mat-sidenav-container> -->
</header>