<div *ngIf="this.collectionArray!= undefined && this.collectionArray[0] !=undefined" class="catHeadingWrapper">
  <p class="catHeading">{{this.collectionArray[0].collectionTitleName}}</p>
</div>
<div *ngIf="this.collectionArray!= undefined && this.collectionArray[0] !=undefined" class="mainDiv">
  <span [class.gayab]="!leftVisibility" (click)="moveToRight()" class="buttons fa fa-chevron-left"></span>
  <div class="productsWrapper">
    <div [style.left]="left + 'px'" class="productList">
      <div class="products" *ngFor="let i of collection1">
        <app-product-tile [tile-data]="i" style="width: 100%"></app-product-tile>
      </div>
    </div>
  </div>
  <span [class.gayab]="!rightVisibility" (click)="moveToLeft($event)" class=" fa fa-chevron-right  buttons"></span>
</div>
<div *ngIf="this.collectionArray!= undefined && this.collectionArray[1] !=undefined" class="catHeadingWrapper">
  <p class="catHeading">{{this.collectionArray[1].collectionTitleName}}</p>
</div>
<div *ngIf="this.collectionArray!= undefined && this.collectionArray[1] !=undefined" class="mainDiv">
  <span [class.gayab]="!leftVisibility2" (click)="moveToRight2()" class="buttons fa fa-chevron-left"></span>
  <div class="productsWrapper">
    <div [style.left]="left2 + 'px'" class="productList">
      <div class="products" *ngFor="let i of collection2">
        <app-product-tile [tile-data]="i" style="width: 100%"></app-product-tile>
      </div>
    </div>
  </div>
  <span [class.gayab]="!rightVisibility2" (click)="moveToLeft2($event)" class="buttons fa fa-chevron-right"></span>
</div>