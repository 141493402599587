import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactQueryModel } from '../../../common/models/contact-us-query-model';
import { ContactService } from '../../../services/contact.service';
import { LsDialogService, LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LoaderService } from '../../../services/loader.service';
import { isPlatformBrowser } from '@angular/common';
import * as FormData from 'form-data';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  formData = new FormData();
  partnersForm: FormGroup;
  selectedFile: File;
  dialog: LsMdDialog<PartnersComponent>;
  currUser;
  currUser_email;
  currUser_name;

  constructor(
    public formBuilder: FormBuilder,
    private contactService: ContactService,
    public dialogService: LsDialogService<OkayMessageComponent>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.partnersForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      message: ["", [Validators.required]],
      file: ["", [Validators.required]]
    });
    this.getCurrUser();
  }

  getCurrUser(){
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        if (this.currUser.email) {
          this.currUser_email = this.currUser.email;
        }
        if (this.currUser) {
          if (this.currUser.fName)
            this.currUser_name = this.currUser.fName
          if (this.currUser.fName && this.currUser.lName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.lName
          if (this.currUser.fName && this.currUser.mName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.mName
          if (this.currUser.fName && this.currUser.mName && this.currUser.lName)
            this.currUser_name = this.currUser.fName + ' ' + this.currUser.mName + ' ' + this.currUser.lName
        } else if (!this.currUser_name && this.currUser.userName) {
          this.currUser_name = this.currUser.userName;
        }
      }
    }
  }

  closeDialog() {
    if(this.dialog){
      this.dialog.close(null);//fns to close dialog
    }
  }

  onFileChange(event) {
    this.selectedFile = event.target.files[0];
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
    }
  }

  submitPartners(errorTag) {
    let queryModel: ContactQueryModel = new ContactQueryModel(), name: string ;
    if(this.currUser_name){
      name = this.currUser_name;
    } else {
      name = this.partnersForm.value.name;
    }
    if(this.currUser_email){
      queryModel.email = this.currUser_email;
    } else {
      queryModel.email = this.partnersForm.value.email;
    }
    queryModel.desc = this.partnersForm.value.message;
    this.formData.append("file", this.selectedFile);
    this.formData.append("data", JSON.stringify(queryModel));
    if (this.selectedFile != undefined && this.selectedFile.size > 5242880) {
      this.dialogService.open(OkayMessageComponent, {}, "File size should be less than 5 MB!!").subscribe({

      })
    } else if (queryModel.email === "" || queryModel.email === null) {
      errorTag.innerHTML = "Please enter your email ID!";
    } else {
      errorTag.innerHTML = "";
      this.contactService.sendGetForMeRequest(this.formData, queryModel.email, name, resp => {
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          this.partnersForm.reset();
          if(this.dialog){
            this.closeDialog();
          }
        })
      })
    }
  }

}
