<div class="catHeadingWrapper">
  <p class="catHeading">Our Offer</p>
</div>


<div class="offerWrapper">

  <div class="offer" *ngFor="let offer of offers; let idx  = index" [title]="offer?.altText">
    <img (click)= "navigate(offer.imageHref)" class="offerImage" *ngIf="offer?.videoURL=='' || (!offer?.videoURL) " [src]="isMobile?offer.mobilUrl:offer.imageName" alt="" (error)="offerImgErrorHandler($event)">
    <iframe class="offerVideo" *ngIf="!(offer?.videoURL=='' || offer?.videoURL == undefined)" [src]="offer?.videoURL | safeUrl" frameborder="0"></iframe>
  </div>


</div>