<div class="contact-us" fxLayout="column">
  <p class="heading">Contact Us</p>
  <div class="contact-upper" fxLayout.gt-xs="row" fxLayout.lt-sm="column">
    <div class="vender-info contact-left" fxLayout="column" fxFlex.gt-xs="34" fxFlex.lt-sm="100">
      <div *ngIf="vendorDetails != undefined" class="contact-mail" fxLayout="column">
        <p class="sub-heading">Mail Us</p>
        <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
      </div>
      <div class="contact-call" fxLayout="column">
        <p class="sub-heading">Call Us</p>
        <p>{{vendorDetails.ccContactNo}}</p>
      </div>
      <div class="contact-office" fxLayout="column">
        <p class="sub-heading">Corporate Office</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.address}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
      </div>
    </div>
    <div class="contact-map contact-right" fxLayout="row" fxFlex.gt-xs="66" fxFlex.lt-sm="100">
      <iframe *ngIf='googleMapUrl != null || googleMapUrl != undefined' [attr.src]="googleMapUrl | safeUrl" style="height:400px; border:1px solid #005825;"></iframe>
    </div>
  </div>
  <div class="contact-lower" fxLayout="column">
    <p class="query-head">Write a Query : </p>
    <form fxLayout="column" enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
      <input *ngIf="!currUser_name" class="text-field" placeholder="Name*" type="text" maxlength="100" formControlName="name">
      <div *ngIf="currUser_name" class="label">
        <p>Name :</p>
        <p>{{currUser_name}}</p>
      </div>
      <select class="text-field" formControlName="queryOption">
          <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
        </select>
      <input *ngIf="!currUser_email" class="text-field" placeholder="Email Address*" type="email" maxlength="256" formControlName="email">
      <div *ngIf="currUser_email" class="label">
        <p>Email :</p>
        <p>{{currUser_email}}</p>
      </div>
      <input *ngIf="!currUser_mobile" class="text-field" placeholder="Phone No." formControlName="phoneNum" maxlength="13" (keypress)="keyPress($event)" type="text">
      <div *ngIf="currUser_mobile" class="label">
        <p>Phone No :</p>
        <p>{{currUser_mobile}}</p>
      </div>
      <textarea class="text-field text-area" placeholder="Message (max. 500 characters)" style="resize:none" formControlName="message"></textarea>
      <div class="attch-file" style="position:relative;">
        <p #fileName style="display:inline-block;color:#000;pointer-events:none;"></p><span #changeMenu class="attchment" style="font-weight:bold"> + Add Attachment</span>
        <input type="file" class="file-upload" style="opacity:0;position:absolute; top:0;left:0;width:50%;" (change)="onFileChange($event, fileName, changeMenu)"></div>
      <button class="submit" [disabled]="!contactForm.valid">Submit</button>
    </form>
  </div>

</div>