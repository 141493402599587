import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LsMdDialog } from '../../../../LSNG/components/ls-dialog/ls-dialog.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OkayMessageComponent } from '../../../commonComponent/okay-message/okay-message.component';
import { GetUserInfoService } from '../../../../services/getUserInfo.service';
import { LsDialogService } from '../../../../LSNG/components/ls-dialog/ls-dialog.service';
import { SelectItemModel } from '../../../../common/models/select-item.model';
import { StaticPageModel } from '../../../../common/models/static-page-model';
import { OrderProductDetailDto } from '../../../../common/models/orderProductDetailDto';
import { TrackOrderService } from '../../../../services/track-order.service';
import { ReturnModel } from '../../../../common/models/returnModel';
import { HomePageService } from '../../../../services/home-page.service';

@Component({
  selector: 'app-return-product',
  templateUrl: './return-product.component.html',
  styleUrls: ['./return-product.component.scss']
})
export class ReturnProductComponent implements OnInit {

  lblReturn = "Return"
  lblForReturnType = "Return Type"
  lblForReason = "Reason"
  lblForDesc = "Description"
  retAndRefLbl = "*Return and Refund Policy"
  lblForPickup = "Pickup on"
  bankdtlsLbl = "Bank Details"
  lblForSelectVariant = "Select a Variant"
  dialog: LsMdDialog<ReturnProductComponent>
  returnModel: ReturnModel = new ReturnModel()
  dataList
  returnForm: FormGroup
  replacementForm: FormGroup
  retAndRefUrl: string
  pickupDate
  currVariantId
  selectionArray = []
  defaultSelectedArray = []
  returnTypeList: Array<SelectItemModel> = new Array<SelectItemModel>()
  reasonList: Array<SelectItemModel> = new Array<SelectItemModel>()
  stateList: Array<SelectItemModel> = new Array<SelectItemModel>()
  quickLinks: Array<StaticPageModel>
  list = []
  isDefaultAddress: boolean = true
  showDeliveryAddress: boolean = true
  showBankForm: boolean = false
  showOtherVariantsAvailable = false
  filterSelected = false
  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  variantMap = new Map<number, { index: number, mapped: Array<number> }>();
  refund = 1
  replacement = 2

  constructor(
    public formBuilder: FormBuilder,
    public trackOrderService: TrackOrderService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public getUserInfoService: GetUserInfoService,
    public router: Router,
    public homePageService: HomePageService
  ) { }

  ngOnInit() {
    this.returnForm = this.formBuilder.group({
      returnType: [-1, [Validators.required]],
      reason: [-1, [Validators.required]],
      desc: [""],
      pickupDate: ["", [Validators.required]],
      pickupTime: [""],
      defaultAddr: [true, [Validators.required]],
      addrName: [""],
      addrLine1: ["", [Validators.required]],
      addrLine2: [""],
      pinCode: ["", [Validators.required], Validators.minLength(6)],
      state: [-1, [Validators.required]],
      city: ["", [Validators.required]],
      tellNo: ["", [Validators.required], Validators.minLength(10)],
      nameAsPerBank: ["", [Validators.required]],
      bankAccountNo: ["", [Validators.required]],
      bankName: ["", [Validators.required]],
      ifscCode: ["", [Validators.required]]
    })
    this.replacementForm = this.formBuilder.group({
      returnType: [-1, [Validators.required]],
      reason: [-1, [Validators.required]],
      desc: [""],
      pickupDate: ["", [Validators.required]],
      pickupTime: [""],
      defaultAddr: [true, [Validators.required]],
      addrName: [""],
      addrLine1: ["", [Validators.required]],
      addrLine2: [""],
      pinCode: ["", [Validators.required], Validators.minLength(6)],
      state: [-1, [Validators.required]],
      city: ["", [Validators.required]],
      tellNo: ["", [Validators.required], Validators.minLength(10)],
    })
    this.getQuickLinks()
    this.getReturnDetails()
    this.populateStateCombo()
    this.getVariants()
  }

  getReturnDetails() {
    let orderProductModel: OrderProductDetailDto = new OrderProductDetailDto()
    orderProductModel.titleId = this.dataList.orderModel.titleId
    orderProductModel.variantId = this.dataList.orderModel.variantId
    orderProductModel.packageId = this.dataList.orderModel.packageId
    orderProductModel.returnGroupId = this.dataList.orderModel.returnGroupId
    orderProductModel.amount = this.dataList.orderModel.amount;
    orderProductModel.productPrice = this.dataList.orderModel.productPrice
    this.trackOrderService.getReturnDetails(orderProductModel, (resp) => {
      if (resp) {
        if (resp.data) {
          this.list = resp.data.list;
          this.populateReturnTypeCombo();
          this.populateReasonCombo();
        }
      }
    })
  }

  getVariants() {
    if (this.dataList.orderModel.productModel) {
      if (this.dataList.orderModel.productModel.variantAttrMap != undefined) {
        let tempVariantArr = this.dataList.orderModel.productModel.variantAttrMap
        Object.keys(tempVariantArr).forEach(key => {
          let filters = tempVariantArr[key]
          let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
          Object.keys(filters).forEach(element => {
            let idxArr: Array<number> = []
            filters[element].forEach(idxValue => {
              idxArr.push(idxValue);
            });
            filtersArr.push({
              option: element,
              mappedUniqueIds: idxArr
            })
          })
          this.variantTypes.push({
            variantHeading: key,
            options: filtersArr
          })
        })
        this.setDefaultSelectedVariants();
      }
    }
  }

  setDefaultSelectedVariants() {
    this.currVariantId = this.dataList.orderModel.variantId
    let defaultVariant = this.dataList.orderModel.variantInformation
    let variantAttrMap: Array<{ type: string, option: any }> = []
    Object.keys(defaultVariant).forEach(key => {
      variantAttrMap.push({
        type: key,
        option: defaultVariant[key]
      })
    })
    for (let type = 0; type < this.variantTypes.length; type++) {
      for (let i = 0; i < variantAttrMap.length; i++) {
        if (this.variantTypes[type].variantHeading == variantAttrMap[i].type) {
          for (let j = 0; j < this.variantTypes[type].options.length; j++) {
            if (this.variantTypes[type].options[j].option == variantAttrMap[i].option) {
              this.selectionArray[type] = j;
            }
          }
        }
      }
    }
    this.defaultSelectedArray = this.selectionArray;
  }

  selectedVariant($event, variantTypeIdx, optionIdx, mappedUniqueIds) {
    this.selectionArray[variantTypeIdx] = optionIdx
    this.filterSelected = true
    if (!this.variantMap.has(variantTypeIdx)) {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    } else {
      this.variantMap.set(variantTypeIdx, {
        index: optionIdx,
        mapped: mappedUniqueIds
      })
    }
    if (this.variantMap.size == this.variantTypes.length) {
      this.getVariantId(this.variantMap);
    } else {
      this.selectionArray = this.defaultSelectedArray;
    }
  }

  getVariantId(map: Map<number, { index: number, mapped: Array<number> }>) {
    let resId;
    if (map.size == 1) {
      resId = map.get(0).mapped;
    } else {
      let temp = []
      let inited = false
      map.forEach(value => {
        if (!inited) {
          inited = true
          temp = value.mapped
        } else {
          temp = this.intersectArray(temp, value.mapped);
        }
      })
      resId = temp
    }
    this.currVariantId = resId[0];
  }

  intersectArray(arr1, arr2) {
    return arr1.filter(function (n) {
      return arr2.indexOf(n) !== -1
    })
  }

  addNewAddress(event) {
  }

  populateStateCombo() {
    let selectItem = new SelectItemModel()
    selectItem.SelectItem(-1, "--Select State--");
    this.stateList.push(selectItem);
    this.getUserInfoService.getStateList((data) => {
      if (data != null) {
        let stateList = data.data;
        stateList.forEach(ele => {
          selectItem = new SelectItemModel()
          selectItem.SelectItem(ele.stateCode, ele.stateName);
          this.stateList.push(selectItem);
        })
      }
    })
  }

  getQuickLinks() {
    this.homePageService.quicLinksObservable.subscribe((quickLinks) => {
      this.quickLinks = quickLinks
      if (this.quickLinks && this.quickLinks.length > 0) {
        this.quickLinks.forEach(link => {
          if (link.linkName == "Return & Refund") {
            this.retAndRefUrl = link.linkPath;
          }
        })
      }
    })
  }

  populateReturnTypeCombo() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select Return Type---');
    this.returnTypeList.push(selectItem);
    Object.keys(this.list[0].returnType).forEach(ele => {
      selectItem = new SelectItemModel()
      selectItem.SelectItem(ele, this.list[0].returnType[ele]);
      this.returnTypeList.push(selectItem);
    })
  }

  populateReasonCombo() {
    let selectItem: SelectItemModel = new SelectItemModel();
    selectItem.SelectItem(-1, '---Select a Reason---');
    this.reasonList.push(selectItem);
    this.list.forEach(ele => {
      selectItem = new SelectItemModel()
      selectItem.SelectItem(ele.reasonId, ele.reason);
      this.reasonList.push(selectItem);
    })
  }

  goToReturnAndRefund() {
    this.closeDialog();
    this.router.navigate(['return-and-refund']);
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressDOB(event: any) {
    const pattern = /[0-9\+\-\/\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateTimeField() {

  }

  onChangeReturnType(targetvalue) {
    if (targetvalue == this.refund) {
      this.showBankForm = true
      this.showOtherVariantsAvailable = false
    } else if (targetvalue == this.replacement) {
      this.showOtherVariantsAvailable = true
      this.showBankForm = false
    }
  }

  hideDeliveryAddress() {
    this.showDeliveryAddress = false;
  }

  _showDeliveryAddress() {
    this.showDeliveryAddress = true;
  }

  closeDialog() {
    this.dialog.close(null);
  }

  returnFormSubmit(event: Event, errorLabel) {
    if (this.returnForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Empty field(s)!";
    }
    if (this.returnForm) {
      this.returnModel.orderId = this.dataList.order.orderId;
      this.returnModel.orderDetailId = this.dataList.orderModel.orderDetailId;
      this.returnModel.titleId = this.dataList.orderModel.titleId;
      this.returnModel.packageId = this.dataList.orderModel.packageId;
      this.returnModel.variantId = this.dataList.orderModel.variantId
      this.returnModel.amount = this.dataList.orderModel.amount;
      this.returnModel.productPrice = this.dataList.orderModel.productPrice
      if (this.dataList.orderModel.productModel.variantAttrMap) {
        this.returnModel.sizeRecNo = this.currVariantId
      } else {
        this.returnModel.sizeRecNo = 0
      }
      this.returnModel.returnType = parseInt(this.returnForm.value.returnType)
      this.returnModel.reasonId = parseInt(this.returnForm.value.reason)
      this.returnModel.description = this.returnForm.value.desc
      this.returnModel.pickUpDate = new Date(this.returnForm.value.pickupDate)
      this.returnModel.pickUpTime = this.returnForm.value.pickupTime
      if (this.returnForm.value.defaultAddr) {
        this.returnModel.resAdd = this.dataList.order.address1 + this.dataList.order.address2
        this.returnModel.city = this.dataList.order.city
        this.returnModel.state = this.dataList.order.state
        this.returnModel.pinCode = this.dataList.order.pinCode
        this.returnModel.tellNo = this.dataList.order.phone
      } else {
        this.returnModel.resAdd = this.returnForm.value.addrName + this.returnForm.value.addrLine1 + this.returnForm.value.addrLine2
        this.returnModel.city = this.returnForm.value.city
        this.returnModel.state = this.returnForm.value.state
        this.returnModel.pinCode = this.returnForm.value.pinCode
        this.returnModel.tellNo = this.returnForm.value.tellNo
      }
      if (this.returnForm.value.returnType == this.refund) {
        this.returnModel.nameAsPerInBank = this.returnForm.value.nameAsPerBank
        this.returnModel.bankName = this.returnForm.value.bankName
        this.returnModel.accountNumber = this.returnForm.value.bankAccountNo
        this.returnModel.ifsc = this.returnForm.value.ifscCode
      }
    }
    this.returnModel.trackingId = this.dataList.orderModel.trackingId
    if (this.returnForm.status === "VALID") {

    }
    this.trackOrderService.returnItems(this.returnModel, (resp) => {
      if (!resp.error) {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(responsse => {
        })
      } else {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(responsse => {
        })
      }
    })
  }

}
