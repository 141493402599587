import { Component, OnInit, Inject, PLATFORM_ID, HostListener, ViewChild, OnDestroy } from '@angular/core'
import { HttpService } from '../../../services/http.service'
import { isPlatformBrowser } from '@angular/common'
import { Router, RouterEvent, ActivatedRoute, NavigationEnd, NavigationStart, RoutesRecognized, NavigationCancel, NavigationError } from '@angular/router'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { CategoryService } from '../../../services/category.service'
import { FilterModel } from '../../../common/models/filterModel'
import { ProductModel } from '../../../common/models/productModel'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { FiltersComponent } from '../filters/filters.component'
import { SeoService } from '../../../services/seo.service';
import { CartCountService } from '../../../services/cart-count.service';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { HomePageService } from '../../../services/home-page.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { ProductFilterModel } from '../../../common/models/productFilterModel';
import { PartnersComponent } from '../partners/partners.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  @ViewChild(FiltersComponent, { static: false }) filtersComponent: FiltersComponent

  navIsFixed: boolean = false;
  pageTitle: string = ""
  currType: string = "INR"
  catgName: string
  products: Array<ProductModel> = []
  currencyId
  categoryId
  categoryUrl
  currUrl
  pageNum
  numRecords
  divisionId
  FilterModel: Array<FilterModel> = []
  categoryList = []
  CATEGORIES: Array<{ catName: string, catId: string, catUrl: string }> = []
  catMap: Array<{ catName: string, catUrl: string }> = []
  hasMore: boolean = false
  list_View: boolean = false
  filterList: Array<FilterModel> = []
  filterId = []
  filterValueId = []
  listViewStr = "grid";
  searchResultsPage: boolean = false;
  showError: boolean = false;
  parentClass: string = "";
  qParams
  params
  route
  content: string
  cdnPathForContent: string
  showSeo: boolean = true
  errorPage: boolean = false
  errorPageUrl = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.errorPage400 : TemplateConstants.templateAssetsPath + '/images/errorPages/404_pageNotFound.jpg';
  searchPageNum = 1;
  parentCategoryUrl: string = '';
  _cartCountSubscription;
  _wishlistSubscription;
  _routeSubscription;
  _catSubscription;
  _routeSubscription2;
  _paramsSubscription;
  cartSettings = Constants.ONLINE_STORE_SETTING_MODEL.cartSettingsDTO;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryListPageService: CategoryListPageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private categoryListService: CategoryListPageService,
    private httpService: HttpService,
    private seoService: SeoService,
    public cartCountService: CartCountService,
    public cartService: CartService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    public dialogService: LsDialogService<LoginComponent>,
    private partnerDialogService: LsDialogService<PartnersComponent>
  ) { }

  ngOnInit() {
    let routerNavFlag = false
    this.route = this.router.url
    if (this.router.url.includes('collections/')) {
      this.parentCategoryUrl = this.router.url.split("collections/")[1].split("?")[0];
    }
    this.activatedRoute.queryParamMap.subscribe(queryParamMap => {
      this.searchPageNum = 1
      this.getSearchData(this.searchPageNum)
    })
    if (this.activatedRoute.snapshot.queryParamMap != undefined && this.activatedRoute.snapshot.queryParamMap.get("k") != undefined) {
      this.getSearchData(this.searchPageNum);
      this.searchResultsPage = true;
    } else {
      this.searchResultsPage = false;
      if (isPlatformBrowser(this.platformId)) {
        if (window.sessionStorage.getItem('currencyId') != null) {
          let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
          let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
          currMap.forEach(element => {
            if (element.id == currId) {
              this.currType = element.code;
            }
          });
        }
      }
      this._routeSubscription2 = this.router.events.subscribe((e: RouterEvent) => {
        if (e instanceof NavigationStart) {
        }
        if (e instanceof NavigationEnd) {
          let newUrl = e.url;
          routerNavFlag = true;
          this.route = newUrl;
          if (newUrl.includes('collections/')) {
            this.currUrl = newUrl;
            let parentCatUrl = this.currUrl.split("collections/")[1].split("?")[0];
            if (!newUrl.includes('?')) {
              this.getFilters(parentCatUrl);
            }
          }
        }
        if (event instanceof NavigationCancel) {
        }
        if (event instanceof NavigationError) {
        }
      });
      if (!routerNavFlag) {
        this.route = this.router.url;
        let queryParams = this.router.routerState.snapshot.root.queryParams;
        this.setQueryParams(queryParams);
        this.getFilters(this.parentCategoryUrl);
      }
      this._paramsSubscription = this.activatedRoute.queryParams.subscribe(data => {
        this.setQueryParams(data);
      });
    }
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._routeSubscription2) {
      this._routeSubscription2.unsubscribe();
    }
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
  }

  setQueryParams(queryParams) {
    if (queryParams && queryParams.setTypeData != undefined && queryParams.dataFor != undefined) {
      let parentIdx = queryParams.setTypeData;
      let currentIdx = queryParams.dataFor;
      let parentIdxArr = [];
      let currentIdArr = [];
      parentIdx.split('#').forEach(ele => {
        parentIdxArr.push(parseInt(ele));
      });
      currentIdx.split('#').forEach(ele => {
        currentIdArr.push(parseInt(ele));
      });
      this.filterId = parentIdxArr;
      this.filterValueId = currentIdArr;
    } else {
      this.filterId = [];
      this.filterValueId = [];
    }
  }

  showMore() {
    if (this.currType != undefined) {
      this.currencyId = 1
    }
    if (this.searchResultsPage) {
      this.getSearchData(this.searchPageNum + 1)
    } else {
      this.categoryUrl = this.router.url.split("collections/")[1].split("?")[0];
      this.pageNum = this.pageNum + 1;
      this.divisionId = 0;
      let filterModel = [];
      this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
      let productFilterModel = new ProductFilterModel();
      productFilterModel.categoryUrl = this.categoryUrl;
      productFilterModel.pageNum = this.pageNum;
      productFilterModel.numRecords = this.numRecords;
      productFilterModel.currencyId = this.currencyId;
      productFilterModel.divisionId = this.divisionId;
      productFilterModel.filterModels = filterModel;
      this.categoryListPageService.getDataOnCategory(productFilterModel, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, (resp) => {
        if (resp != null) {
          if (!resp.error) {
            if (resp.data) {
              this.cdnPathForContent = resp.data.data.seoUrl
              if (this.cdnPathForContent != undefined && this.cdnPathForContent != "") {
                this.httpService.getCDNContent(this.cdnPathForContent, (data) => {
                  if (data != undefined && data != "")
                    this.content = data;
                });
              }
              this.pageTitle = resp.data.data.categoryDisplayName
              this.hasMore = resp.data.hasMore
              this.catMap = []
              Object.keys(resp.data.map).forEach(ele => {
                this.catMap.push({
                  catName: ele,
                  catUrl: resp.data.map[ele]
                })
              })
              resp.data.list.forEach(element => {
                this.products.push(element)
              });
            }
          }
        }
      })
    }
  }

  getDataOnCategory(filterModel, pageNum) {
    if (this.currType != undefined) {
      this.currencyId = 1
    }

    /**
     * for nav end
     */
    let routerUrl
    if (this.router.url && this.router.url.split('collections/')[1]) {
      routerUrl = this.router.url.split("collections/")[1].split("?")[0]
    }
    if (this.currUrl == undefined) {
      this.categoryUrl = routerUrl
    } else {
      if (this.currUrl && this.currUrl.split('collections/')[1]) {
        this.categoryUrl = this.currUrl.split("collections/")[1].split("?")[0]
      }
    }

    this.pageNum = pageNum;
    this.divisionId = 0;
    this.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    let productFilterModel = new ProductFilterModel();
    productFilterModel.categoryUrl = this.categoryUrl;
    productFilterModel.pageNum = this.pageNum;
    productFilterModel.numRecords = this.numRecords;
    productFilterModel.currencyId = this.currencyId;
    productFilterModel.divisionId = this.divisionId;
    productFilterModel.filterModels = filterModel;
    this.categoryListPageService.getDataOnCategory(productFilterModel, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, (resp) => {
      if (resp != null) {
        if (!resp.error) {
          this.errorPage = false
          if (resp.data && resp.data.data) {
            if (resp.data.data.metaTitle && resp.data.data.metaKeyword && resp.data.data.metaDesc) {
              this.seoService.updateMetaTags(resp.data.data.metaTitle, resp.data.data.metaKeyword, resp.data.data.metaDesc)
            }
            this.cdnPathForContent = resp.data.data.seoUrl
            if (this.cdnPathForContent != undefined && this.cdnPathForContent != "") {
              this.httpService.getCDNContent(this.cdnPathForContent, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
            this.pageTitle = resp.data.data.categoryDisplayName
            this.hasMore = resp.data.hasMore
            this.catMap = []
            Object.keys(resp.data.map).forEach(ele => {
              this.catMap.push({
                catName: ele,
                catUrl: resp.data.map[ele]
              })
            })
            if (resp.data.list) {
              this.errorPage = false
              this.products = resp.data.list
            } else {
              this.products = []
              this.errorPage = true
            }
          } else if (resp.data == null) {
            this.products = []
            this.errorPage = true
          }
          //else if (resp.data != null) {
        } else {
          this.errorPage = true
          if (resp.data) {
            this.cdnPathForContent = resp.data.data.seoUrl
            if (this.cdnPathForContent != undefined && this.cdnPathForContent != "") {
              this.httpService.getCDNContent(this.cdnPathForContent, (data) => {
                if (data != undefined && data != "")
                  this.content = data;
              });
            }
            this.pageTitle = resp.data.data.categoryDisplayName
            this.hasMore = resp.data.hasMore
            this.catMap = []
            Object.keys(resp.data.map).forEach(ele => {
              this.catMap.push({
                catName: ele,
                catUrl: resp.data.map[ele]
              })
            })
            this.products = resp.data.list
          }
        }
      }
    })
  }

  navigateToCategoryUrl(url) {
    this.router.navigate(['collections/' + url]);
  }

  listView() {
    this.list_View = true
    this.listViewStr = "list"
  }

  gridView() {
    this.list_View = false
    this.listViewStr = "grid"
  }

  updateCollection(event) {
    this.FilterModel = [];
    this.FilterModel = event;
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  getFilters(categoryUrl: string) {
    this.filterList = [];
    this.FilterModel = [];
    this.categoryListService.getFilters(categoryUrl, resp => {
      if (resp.error) {
      } else {
        if (resp.data != undefined) {
          resp.data.forEach(ele => {
            this.filterList.push(ele)
          })
        }
        this.setSelectedFilters();
      }
    })
  }

  setSelectedFilters() {
    if (this.filterId.length != 0 && this.filterValueId.length != 0 && this.FilterModel != undefined && this.filterList != undefined) {
      for (let i = 0; i < this.filterId.length; i++) {
        let model: FilterModel = new FilterModel(); let flag = false;
        if (this.FilterModel != undefined && this.FilterModel.length > 0) {
          for (let filter = 0; filter < this.FilterModel.length; filter++) {
            if (this.FilterModel[filter].filterId == this.filterId[i]) {
              for (let j = 0; j < this.filterList.length; j++) {
                if (this.filterList[j].filterId == this.filterId[i]) {
                  for (let k = 0; k < this.filterList[j].filterModelList.length; k++) {
                    if (this.filterList[j].filterModelList[k].filterValueId == this.filterValueId[i]) {
                      this.FilterModel[filter].filterModelList.push(this.filterList[j].filterModelList[k]);
                      flag = true;
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        if (!flag) {
          for (let i1 = 0; i1 < this.filterList.length; i1++) {
            if (this.filterList[i1].filterId == this.filterId[i]) {
              for (let j1 = 0; j1 < this.filterList[i1].filterModelList.length; j1++) {
                if (this.filterList[i1].filterModelList[j1].filterValueId == this.filterValueId[i]) {
                  model.colorAvailable = this.filterList[i1].colorAvailable
                  model.filterId = this.filterList[i1].filterId
                  model.filterName = this.filterList[i1].filterName
                  model.filterType = this.filterList[i1].filterType
                  model.filterTypeId = this.filterList[i1].filterTypeId
                  model.seqNo = this.filterList[i1].seqNo;
                  model.filterModelList = new Array();
                  model.filterModelList.push(this.filterList[i1].filterModelList[j1]);
                  this.FilterModel.push(model);
                  break
                }
              }
              break
            }
          }
        }
      }
    }
    this.getFilterDisplayNameList();
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum);
  }

  _showFilters: boolean = false
  showFilters() {
    this._showFilters = true;
    if (this._showFilters) {
      this.showSeo = false
    } else {
      this.showSeo = true
    }
  }

  hideFiltersPanel() {
    this._showFilters = false;
    if (this._showFilters) {
      this.showSeo = false
    } else {
      this.showSeo = true
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (window.pageYOffset > 2740) {
      this.navIsFixed = false;
    } else if (number > 160) {
      this.navIsFixed = false;
    } else if (this.navIsFixed && number < 159) {
      this.navIsFixed = false;
    }
  }

  getSearchData(pageNum) {
    this.searchPageNum = pageNum
    let keyword = this.activatedRoute.snapshot.queryParamMap.get("k");
    if (keyword != undefined && keyword != "") {
      if (isPlatformBrowser(this.platformId)) {
        if (window.sessionStorage.getItem('currencyId') != null) {
          let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
          let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
          currMap.forEach(element => {
            if (element.id == currId) {
              this.currType = element.code;
            }
          });
        }
      }
      if (this.currType != undefined) {
        this.currencyId = 1
      }
      this.categoryListPageService.searchResults(this.currencyId, keyword, pageNum, Constants.SEARCH_PRODUCTS_REQ_MODE.BOTH, resp => {
        if (resp != null) {
          if (!resp.error) {
            if (resp.data && resp.data.list) {
              this.pageTitle = "Search Products for " + keyword;
              this.hasMore = resp.data.hasMore
              this.products = resp.data.list
              this.searchResultsPage = true;
              this.parentClass = "searchResultsPage";
              this.showError = false;
            } else {
              this.showError = true;
              this.parentClass = "no-results-found";
            }
          } else {
            this.showError = true;
            this.parentClass = "no-results-found";
          }
        }
      })
    }
  }

  clearAllFilters() {
    this.FilterModel = [];
    this.filterList = [];
    this.filterId = [];
    this.filterValueId = [];
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    this.router.navigate([this.route.split("?")[0]])
    this.filtersComponent.checkRemoveAll()
  }

  remove(event) {
    if (this.FilterModel[0] != undefined && this.FilterModel[0].filterModelList.length == 0) {
      this.FilterModel = []
    }
    this.pageNum = 1;
    this.getDataOnCategory(this.FilterModel, this.pageNum)
    this.filtersComponent.checkRemove(event.filterId, event.filterValueId)
    let len = this.filterId.length
    for (let i = 0; i < len; i++) {
      if (this.filterId[i] == event.filterId && this.filterValueId[i] == event.filterValueId) {
        this.filterId.splice(i, 1)
        this.filterValueId.splice(i, 1)
      }
    }
    this.params = {
      setTypeData: this.filterId.join('#'),
      dataFor: this.filterValueId.join('#')
    }
    if (this.filterId.length > 0) {
      this.qParams = Object.assign({}, this.params)
      this.router.navigate([this.route.split("?")[0]], { queryParams: this.qParams })
    } else {
      this.router.navigate([this.route.split("?")[0]])
    }
  }

 addToWishlist(event: CartProductModel) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(event, false);
    } else {
      this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility(event, false);
        }
      });
    }
  }

addToCartWishlistUtility(event: CartProductModel, isLoginAction: boolean) {      if(!isPlatformBrowser(this.platformId)) {       return    }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(event);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(event.titleId, true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
  }

 checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == id) {
          present = true;
        }
      });
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  getFilterDisplayNameList() {
    if (this.FilterModel && this.FilterModel.length > 0) {
      for (let index = 0; index < this.FilterModel.length; index++) {
        const ele = this.FilterModel[index];
        if (ele && ele.filterModelList && ele.filterModelList.length > 0) {
          for (let j = 0; j < ele.filterModelList.length; j++) {
            const element = ele.filterModelList[j];
            element.displayValue = element.filterDisplayValue;
          }
        }
      }
    }
  }

  getForMe() {
    this.partnerDialogService.open(PartnersComponent, {}, '').subscribe({});
  }

}
