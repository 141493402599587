import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductDetailsService } from '../../../services/product-details.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { ProductModel } from '../../../common/models/productModel';
import { UserModel } from '../../../common/models/user-model';
import { EnquiryModel } from '../../../common/models/enquiry-model';
import { EnquireService } from '../../../services/enquire.service';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartCountService } from '../../../services/cart-count.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { TemplateConstants } from '../../commonComponent/template-constants';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';
import * as FormData from 'form-data';

/**
 * This component displays enquiry-products and enquiry form for submitting product or cart enquiry.
 */
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss'],
  providers: [CartService]

})
export class EnquiryComponent implements OnInit {
  titleIdList: Array<number> = new Array<number>()
  productDetails: Array<ProductModel> = new Array<ProductModel>();
  productList: Array<CartProductModel>;
  enquireDetails: EnquiryModel
  productHref: string
  productTitle: string
  salePrice
  currType: string = "INR"
  selectedFile: File;
  formData = new FormData();
  enquiryForm: FormGroup
  userState: UserModel;
  currUserEmail;
  message: string = "";
  enquiryName: string = "";
  cart: CartModel = new CartModel()
  enqCart: CartModel = new CartModel();
  updateEnquiry: boolean
  defaultPath = TemplateConstants.defaultImgPath;
  priceRoundOffInteger: string = '1.2';
  storeName = Constants.WAR_NAME;
  noDataFound: boolean;
  editEnqFile: string = '';
  sizeRec: number = 0;
  hasVariants: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private enquiryService: EnquireService,
    private loginDialog: LsDialogService<LoginComponent>,
    private dialogService: LsDialogService<OkayMessageComponent>,
    public YesNoDialogService: LsDialogService<YesNoPopupComponent>,
    private cartService: CartService,
    private cartCountService: CartCountService
  ) { }

  /**
   * create enquiry form-group.
   * fetch query-parameter map from current route,
   *   If 'c' is present, then it means the current enquiry is for edit/update. Fetch enquiry-cart using cartId ('c').
   *   Else
   *      If 't' is present, then it means this enquiry is from product-detail page. Fetch titleID-list from 't', variantId from 's' and hasVariant from 'h'.
   *        Then, Fetch cart by preparing cartProductModels using 't', 's' and 'h'.
   *      Else
   *         If 'enquireCart' is present in session-storage, then it means enquiry is from shopping-cart. Fetch current cart by cart-observable or local-storage.
   *         Else If 'enqCartID' is present in local-storage, then it means enquiry is moved for completing payment and added in payment-cart. 
   *            Fetch enquiry-cart using cartId ('enqCartID').
   * finally, if enquiry route is aleady open and customer opens-up cart pop-up and clicks-on enquire, then updateCurrentEnqCartObservable is updated, and current-cart is
   * fetched from cart-observable or local-storage and populated in the enquiry-page.
   */
  ngOnInit() {
    this.buildEnqForm();
    if (this.activatedRoute.snapshot.queryParamMap.get("c") != null) {
      let cartId = this.activatedRoute.snapshot.queryParamMap.get("c").toString();
      if (cartId != "0") {
        this.updateEnquiry = true;
        this.getParticularCart(parseInt(cartId));
      }
    } else {
      if (this.activatedRoute.snapshot.queryParamMap.get("t") != null) {
        this.titleIdList = [];
        this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
        if (this.activatedRoute.snapshot.queryParamMap.get("s") != null) {
          this.sizeRec = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("s").toString());
        }
        if (this.activatedRoute.snapshot.queryParamMap.get("h") != null) {
          this.hasVariants = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("h").toString());
        }
        this.getEnquiryProductInfo(null);
      } else {
        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('currentUser') != null) {
            this.currUserEmail = JSON.parse(localStorage.getItem('currentUser')).email;
          }
        }
        if (sessionStorage.getItem('enquireCart') && sessionStorage.getItem('enquireCart') == 'true') {
          this.getCurrentCartForEnq();
        } else if (this.getEnqCartID()) {
          this.getParticularCart(this.getEnqCartID());
        } else {
          this.noDataFound = true;
        }
      }
    }
    if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
      if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
          this.priceRoundOffInteger = '1.0'
        } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
          this.priceRoundOffInteger = '1.2'
        }
      }
    }
    this.getCurrentCartAsEnquiry();
  }

  /**
   * subscribe to updateCurrentEnqCartObservable, for fetching current cart as enquiry-cart.
   */
  getCurrentCartAsEnquiry() {
    this.cartCountService.updateCurrentEnqCartObservable.subscribe((resp) => {
      if (resp) {
        this.getCurrentCartForEnq();
      }
    });
  }

  /**
   * fetches cart using provided cartId,
   * re-initialize enquiry-cart with current values.
   * @param cartId 
   */
  getParticularCart(cartId: number) {
    this.enquiryService.getParticularCart(cartId, resp => {
      this.enquireDetails = resp.data[0];
      this.productList = this.enquireDetails.cartProductModels;
      this.updateEnqCart();
    });
  }

  /**
   * returns 'enqCartID' value from local-storage.
   */
  getEnqCartID() {
    return JSON.parse(localStorage.getItem('enqCartID'));
  }

  /**
   * builds form-group for enquiry.
   */
  buildEnqForm() {
    this.enquiryForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      message: ["", [Validators.required, Validators.minLength]]
    });
  }

  /**
   * fetch current shopping-cart as enquiry-cart from cart-observale or local-storage.
   */
  getCurrentCartForEnq() {
    this.cartCountService.cart.subscribe((cart) => {
      this.cart = cart;
      if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
        let cartProducts = this.cart.cartProductModels;
        this.productList = cartProducts;
      } else if (JSON.parse(localStorage.getItem('localCart'))) {
        let newLocalCart = new CartModel();
        newLocalCart.cartProductModels = [];
        let localCart: CartModel = JSON.parse(localStorage.getItem('localCart'));
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          localCart.cartProductModels.forEach(element => {
            let cartProductModel: CartProductModel = new CartProductModel();
            cartProductModel.titleId = element.titleId;
            cartProductModel.variantId = element.variantId;
            cartProductModel.numCopies = element.numCopies;
            newLocalCart.cartProductModels.push(cartProductModel);
          });
          this.getEnquiryProductInfo(newLocalCart);
        }
      }
    });
  }

  /**
   * initializes enquiry-form with current enquiry-form values.
   */
  updateEnqCart() {
    if (this.enquireDetails.enquiryMessage != null) {
      this.enquiryForm.patchValue({
        message: this.enquireDetails.enquiryMessage
      });
    } else {
      this.enquiryForm.patchValue({
        message: ""
      });
    }
    if (this.enquireDetails.enquiryName != null) {
      this.enquiryForm.patchValue({
        name: this.enquireDetails.enquiryName
      });
    } else {
      this.enquiryForm.patchValue({
        name: ""
      });
    }
    this.editEnqFile = this.enquireDetails.enquiryAttachment;
  }

  /**
   * fetch enquiry-cart by provided cart-details or by preparing cart using titleId ('t'), variantId ('s) and hasVariants ('h).
   * @param cart 
   */
  getEnquiryProductInfo(cart: CartModel) {
    let forceFetch = false, validateOffers = true;
    if (!cart) {
      cart = new CartModel();
      cart.cartProductModels = [];
      this.titleIdList.forEach(ele => {
        let cartProductModel: CartProductModel = new CartProductModel();
        cartProductModel.titleId = ele;
        if (this.hasVariants) {
          if (this.sizeRec) {
            cartProductModel.variantId = this.sizeRec;
          } else {
            cartProductModel.variantId = 1;
          }
        } else {
          cartProductModel.variantId = 0;
        }
        cartProductModel.numCopies = 1;
        cart.cartProductModels.push(cartProductModel);
      });
    }
    this.cartService.getCart(cart, validateOffers, forceFetch, (data) => {
      if (!data.error && data.data) {
        this.noDataFound = false;
        this.enqCart = data.data[0];
        if (this.enqCart.cartProductModels && this.enqCart.cartProductModels.length > 0) {
          this.productList = this.enqCart.cartProductModels;
        } else {
          this.noDataFound = true;
        }
      } else {
        this.noDataFound = true;
      }
    });
  }

  /**
   * updates selected file from system.
   * @param event - file selection event
   * @param fileName - element-reference where file-name is to be shown
   * @param changeMenu -  element-reference where 'change' is to be shown when a file is selected.
   */
  onFileChange(event, fileName, changeMenu) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile && this.selectedFile.size > 5242880) {
      this.dialogService.open(OkayMessageComponent, {}, "File size should be less than 5 MB!!").subscribe({
      });
    } else {
      if (event.target.files.length > 0) {
        let fileToUpload = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file[]", fileToUpload, fileToUpload.name);
        fileName.innerHTML = this.selectedFile.name;
        if (!changeMenu.className.includes('changeLnk')) {
          changeMenu.innerHTML = "Change";
          changeMenu.className = "attchment changeLnk";
        }
      }
    }
  }

  /**
   * On submit button click, validation is done for each field present in the enquiry-cart.
   * If user is not logged-in, then first open login pop-up and after successful login proceed to enuiry submission.
   * @param errorFlag 
   */
  submitEnquiry(errorFlag) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.enquiryForm.value.message.length == 0) {
        errorFlag.innerHTML = "Enquiry message is mandatory";
        errorFlag.className = "error-label show";
      }
      else if (this.enquiryForm.value.name.length == 0) {
        errorFlag.innerHTML = "Enquiry Name is mandatory !";
        errorFlag.className = "error-label show";
      }
      else if (localStorage.getItem('currentUser') != null) {
        errorFlag.className = "error-label hide";
        this.userState = JSON.parse(localStorage.getItem('currentUser'));
        this.sendEnquiry();
      } else {
        this.loginDialog.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
          if (localStorage.getItem('currentUser') != null) {
            this.userState = JSON.parse(localStorage.getItem('currentUser'));
            this.sendEnquiry();
          }
        })
      }
    }
  }

  /**
   * submits enquiry by preparing EnuiryModel. 
   * And after successful enquiry generation redirect to 'my-enquiry' page of dashboard section.
   * Also clears current-cart.
   */
  sendEnquiry() {
    let enquiryModel: EnquiryModel = new EnquiryModel();
    let fileName: Array<string> = new Array<string>();
    let updateEnq = false;
    if (this.activatedRoute.snapshot.queryParamMap.get('c') != null) {
      updateEnq = true;
      enquiryModel.cartId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get('c').toString());
      enquiryModel.enquiryAttachment = this.enquireDetails.enquiryAttachment;
    }
    if (this.selectedFile) {
      fileName.push(this.selectedFile.name);
      enquiryModel.enquiryAttachment = this.selectedFile.name;
    }
    enquiryModel.enquiryMessage = this.enquiryForm.value.message;
    enquiryModel.enquiryName = this.enquiryForm.value.name;
    if (this.activatedRoute.snapshot.queryParamMap.get("t") == null && this.activatedRoute.snapshot.queryParamMap.get('c') == null) {
      enquiryModel.fromCart = true;
    }
    let enqProductModels: Array<CartProductModel> = new Array();
    this.productList.forEach(model => {
      let cartProdModel = new CartProductModel();
      cartProdModel.productModel = model.productModel;
      cartProdModel.titleId = model.titleId
      cartProdModel.variantId = model.variantId;
      cartProdModel.numCopies = model.numCopies;
      enqProductModels.push(cartProdModel);
    });
    enquiryModel.cartProductModels = enqProductModels;
    if (this.selectedFile != undefined) {
      this.formData.append('file', this.selectedFile);
    }
    this.formData.append('data', JSON.stringify(enquiryModel));
    this.enquiryService.sendEnquiry(this.formData, enquiryModel, updateEnq, resp => {
      if (!resp.error) {
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          if (isPlatformBrowser(this.platformId)) {
            this.clearCurrCart();
            this.router.navigate(['my-dashboard/enquiries']);
          }
        })
      }
    })
  }

  /**
   * updated product's num-copies in enquiry-cart.
   * @param event 
   * @param product 
   * @param idx 
   */
  updateNumCopies(event, product: CartProductModel, idx: number) {
    let qty: number = parseInt(event.srcElement.value);
    if (qty) {
      if (qty <= 0 && qty < product.productModel.minOrderQty) {
        this.dialogService.open(OkayMessageComponent, {}, 'Minimum quantity reached');
      } else if (qty > product.productModel.maxOrderQty) {
        this.dialogService.open(OkayMessageComponent, {}, 'Maximum quantity reached. Maximum of ' + product.productModel.maxOrderQty + ' products can be enquired.');
      } else {
        if (this.productList && this.productList.length > 0 && this.productList[idx]) {
          this.productList[idx].numCopies = qty;
        } else {
          this.dialogService.open(OkayMessageComponent, {}, 'Some error occurred');
        }
      }
    } else {
      this.dialogService.open(OkayMessageComponent, {}, 'Enter some quantity');
    }
  }

  /**
   * removes a product from enquiry-cart.
   * @param index 
   */
  removeProduct(index: number) {
    this.YesNoDialogService.open(YesNoPopupComponent, {}, "Are you sure you want to remove this product from enquiry?").subscribe(response => {
      if (response == 'yes') {
        this.productList.splice(index, 1);
        if (this.productList.length < 1) {
          this.noDataFound = true;
        }
      }
    });
  }

  /**
   * displays product's variant name.
   * @param obj 
   */
  setVariantName(obj) {
    let a: string = obj[Object.keys(obj)[0]];
    return a;
  }

  /**
   * image error handling
   * @param event 
   */
  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

  /**
   * redirects to product's detail page when clicked on product-image.
   * @param product 
   */
  goToProductDetailPage(product) {
    let url: string;
    if (product.productURLText) {
      url = product.productURLText != undefined ? product.productURLText : '';
    }
    let target = '/details/' + url;
    return target;
  }

  /**
   * prepared query-parameters for product's detail page navigation.
   * @param product 
   */
  getQueryParamsForProduct(product) {
    let queryParams;
    if (product.productModel.titleId) {
      queryParams = { 't': product.productModel.titleId };
    }
    if (product.productModel.titleId && product.variantId) {
      queryParams = { 't': product.productModel.titleId, 'sizeRecNum': product.variantId };
    }
    return queryParams;
  }

  /**
   * clears out current shopping-cart.
   */
  clearCurrCart() {
    this.removeLocalCart();
  }

  /**
   * removes current-cart present in local-storage.
   */
  removeLocalCart() {
    if (!isPlatformBrowser(this.platformId)) { return }
    localStorage.removeItem('localCart');
    this.cartCountService.updateCart(new CartModel());
  }

}
