import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service'
import { BlogDto } from '../../../../../common/models/blogDto'
import { Router } from '@angular/router'
import { TemplateConstants } from '../../../../commonComponent/template-constants';

export class CoOrdinates {
  xCoOrdinate: number
  yCoOrdinate: number
}

export class DIRECTIONS {
  static readonly NO_SWIPE = 'no_swipe'
  static readonly LEFT = 'left'
  static readonly RIGHT = 'right'
  static readonly UP = 'up'
  static readonly DOWN = 'down'
}

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  constructor(
    public homePageService: HomePageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,    
  ) { }

  blogs: Array<BlogDto>
  leftVisibility = false
  rightVisibility = true
  left: boolean = false
  isMobile: boolean = false
  defaultPath = TemplateConstants.templateAssetsPath+"/images/default/blog.jpg";
  height;

  ngOnInit() {
    this.homePageService.getBlogOffer((data) => {
      this.blogs = data.data
      this.blogs = this.blogs.slice(0, 3);
    });
  }

  moveToLeft() {
    this.leftVisibility = true
    this.rightVisibility = false
    this.left = true
  }

  moveToRight() {
    this.leftVisibility = false
    this.rightVisibility = true
    this.left = false
  }

  private startCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  private endCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  touchHandler(event: TouchEvent, type: string): void {
    if (type === 'start') {
      this.startCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
    } else {
      this.endCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
      this.handleCarouselSwipe()
    }

  }

  private handleCarouselSwipe(): void {
    let direction = this.xDirectionFinder()

    if (direction != DIRECTIONS.NO_SWIPE) {
      if (direction === DIRECTIONS.LEFT) {
        this.moveToLeft()

      } else {
        this.moveToRight()

      }
    }
  }

  private xDirectionFinder(): string {
    if (Math.abs(this.startCordinates.xCoOrdinate - this.endCordinates.xCoOrdinate) < 20)
      return DIRECTIONS.NO_SWIPE
    if (this.startCordinates.xCoOrdinate <= this.endCordinates.xCoOrdinate) {
      return DIRECTIONS.LEFT
    } else {
      return DIRECTIONS.RIGHT
    }
  }

  blogErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }
}
