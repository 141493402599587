<div class="catHeadingWrapper">
  <p class="catHeading">Our Blogs</p>
</div>

<div (touchstart)="touchHandler($event, 'start')" (touchend)="touchHandler($event, 'end')" fxLayout="row" class="blogParent"
  [style.height.px]="height">
  <div class="buttonParent" fxLayoutAlign="center center">
    <span [class.gayab]="!leftVisibility" (click)="moveToRight()" class="buttons fa fa-chevron-left"></span>
  </div>
  <div class="blogSlider">
    <div *ngFor="let blog of blogs;let idx = index" class="blogWrapper">
      <div [class.left]="left" class="blog" [title]="blog?.blogTitle">
        <a class="parentA" [routerLink]="'blog/'+blog?.blogUrl"> 
          <img [src]="isMobile?blog?.mobilUrl:blog?.blogImage" (error)="blogErrorHandler($event)" alt="">
          <div class = "layeredDiv"></div>
          <a id = "readMore">Read more...</a>
        </a>
      </div>
    </div>
  </div>
  <div class="buttonParent" fxLayoutAlign="center center">
    <span [class.gayab]="!rightVisibility" (click)="moveToLeft()" class=" fa fa-chevron-right  buttons"></span>
  </div>
</div>