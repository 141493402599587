<div class="partners" [ngClass]="{'extra-padding': dialog}">
  <div class="heading">
    <p class="heading-partner">Partners in Prosperity</p>
    <span [ngClass]="{'hide': !dialog}" class="close-pop-up fa fa-close" (click)="closeDialog()"></span>
  </div>
  <p class="partner-subheading"> Join us in our mission to Protect the Environment by selling our products at very attractive terms</p>
  <div class="form-class">
    <p class="error-label" #errorTag></p>
    <form enctype="multipart/form-data" [formGroup]="partnersForm" (ngSubmit)="submitPartners(errorTag)">
      <input *ngIf="!currUser_name" type="text" placeholder="Your Name" class="text-field" maxlength="100" formControlName="name">
      <div *ngIf="currUser_name" class="label">
        <p>Name :</p>
        <p>{{currUser_name}}</p>
      </div>
      <input *ngIf="!currUser_email" type="email" placeholder="Your Email-id" class="text-field" maxlength="256" formControlName="email">
      <div *ngIf="currUser_email" class="label">
        <p>Email :</p>
        <p>{{currUser_email}}</p>
      </div>
      <textarea placeholder="Remarks" class="text-field" formControlName="message"></textarea>
      <div class="upload-file">
        <input type="file" class="file-upload" formControlName="file" (change)="onFileChange($event)">
      </div>
      <button class="submit-form">Submit</button>
    </form>
  </div>
</div>