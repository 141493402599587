<div *ngIf="!noDataFound" class="enquire">
  <p class="heading">Contact {{storeName}}</p>
  <div id="enquireBdyOuter" class="enquireBdyOuter">
    <div id="enquireContent" class="enquireContent">
      <div id="enquireHeader" class="enquireHeader" fxLayout="row">
        <p fxFlex="16.66666667" fxHide.lt-sm></p>
        <p id="productDescHdr" class="productDescHdr" fxFlex="33.33333333">Product Description</p>
        <p id="QtyHdr" class="QtyHdr" fxFlex="25" fxHide.lt-sm> Qty</p>
        <p id="productPriceHdr" class="productPriceHdr" fxFlex="18" fxHide.lt-sm> Price </p>
      </div>
      <div id="enquireBody" class="enquireBody">
        <ul class="enquireItr">
          <li fxLayout="row" *ngFor="let product of productList; let idx=index;">
              <!-- [href]="'details/'+product?.productModel?.productURLText+'?t='+product?.productModel?.titleId" -->
            <a class="pro-img" fxFlex="16.66666667" [routerLink]="goToProductDetailPage(product?.productModel)" [queryParams]="getQueryParamsForProduct(product)"
              style="position:relative">
              <img [src]="(product?.productModel?.imageUrl && product?.productModel?.images && product?.productModel?.images[0])?product?.productModel?.imageUrl +'m/'+ product?.productModel?.images[0]:defaultPath"
                class="center-position" style="max-height:100%; max-width:100%" (error)="imgErrorHandler($event)">
            </a>
            <div class="pro-desc" fxFlex="33.33333333" fxLayout="column">
              <p class="title">{{product?.productModel?.productName}}</p>
              <p class="size-rec" *ngIf="product?.productModel?.variantAttributes">{{setVariantName(product?.productModel?.variantAttributes)}}</p>
            </div>
            <div class="quantity" fxFlex="25">
              <p fxShow.lt-sm fxHide.gt-xs>Qty : </p>
              <input [value]="product?.numCopies? product?.numCopies : 1" (change)="updateNumCopies($event, product, idx)">
            </div>
            <div class="price" fxFlex="18">
              <p fxShow.lt-sm fxHide.gt-xs>Price : </p>
              <p>{{product?.productModel?.salePrice | currency:currType}}</p>
            </div>
            <div class="remove-enquiry" title="Delete">
              <span (click)='removeProduct(idx)' class="cartDeleteIcon fa fa-trash">
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <form enctype="multipart/form-data" [formGroup]="enquiryForm" (ngSubmit)="submitEnquiry(errorFlag)">
      <div id="enquireMsgPnl" class="enquireMsgPnl">
        <p id="enquireMsgLbl" class="enquireMsgLbl boldLbl">
          Message (max. 500 characters)
        </p>
        <textarea [value]="message" id="enquireMsgTa" class="enquireMsgTa " rows="1" autoresize="false" formControlName="message"
          minLength="50"></textarea>
      </div>
      <div class="attch-file" style="position:relative;">
        <p #fileName style="display:inline-block;color:#000;pointer-events:none;">{{editEnqFile}}</p><span #changeMenu class="attchment"
          style="font-weight:bold"> + Add Attachment</span>
        <input type="file" class="file-upload" style="opacity:0;position:absolute; top:0;left:0;width:50%;" (change)="onFileChange($event, fileName, changeMenu)">
      </div>
      <div id="btnPnlForEnquiry" class="btnPnlForEnquiry" fxLayoutAlign.gt-xs="end start" fxFlexAlign="start"
        fxLayoutAlign.lt-sm="end end">
        <p id="errorLblForEnquiry" class="errorLblForEnquiry hide redLbl">
        </p>
        <div id="enquireNamePnl" class="enquireNamePnl" fxFlex.gt-xs="16.66666667" fxFlex.lt-sm="48">
          <input [value]="enquiryName" type="text" id="enquiryNameTf" class="enquiryNameTf" data-contenttype="0"
            placeholder="Enquiry Name" formControlName="name">
        </div>
        <p class="error-label" #errorFlag></p>
        <div id="sendEnquiryBtnPnl" class="sendEnquiryBtnPnl">
          <button *ngIf="!updateEnquiry" id="sendEnquiry" class="sendEnquiry" data-from-cart="3" data-cart-id="">
            Send Enquiry Now
          </button>
          <button *ngIf="updateEnquiry" id="sendEnquiry" class="sendEnquiry" data-from-cart="3" data-cart-id="">
            Update Enquiry
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="errorPage" fxLayoutAlign="center center" *ngIf="noDataFound">
  <div fxLayout="column" fxLayoutAlign="center center" class="pnlNoResultOuter">
    <div fxLayoutAlign="center center" class="pnlNoResultInner">
      <p class="lblNoResult">No Products found to Enquire !</p>
    </div>
    <div fxLayoutAlign="center center" class="pnlContinueShop">
      <a [routerLink]="''">Continue Shopping...</a>
    </div>
  </div>
</div>