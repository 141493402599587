import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HomePageService } from '../../../../../services/home-page.service'
import { CollectionModel } from '../../../../../common/models/collectionModel'
import { ProductModel } from '../../../../../common/models/productModel'
import { isPlatformBrowser } from '@angular/common'; 

@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  styleUrls: ['./home-products.component.scss']
})
export class HomeProductsComponent implements OnInit {

  constructor( 
    private homePageService:HomePageService,
    @Inject(PLATFORM_ID) private platformId : Object
  ) { }
  left:number= 0
  rightVisibility:boolean = true
  leftVisibility:boolean = false
  slideConst:number
  slideLength:number
  iteration:number = 0
  currIteration:number = 1

  collectionArray : Array<CollectionModel>;
  collection1:Array<ProductModel>
  collection2:Array<ProductModel>

  left2:number= 0
  rightVisibility2:boolean = true
  leftVisibility2:boolean = false
  slideConst2:number
  slideLength2:number
  iteration2:number = 0
  currIteration2:number = 1
  
  showCount:number = 4

    ngOnInit() {
      if(isPlatformBrowser(this.platformId)){
        if(window.innerWidth< 768){
          this.showCount = 2;
        }
      }
    
      this.homePageService.getHomePageCollection((data)=>{
        if(data != null && !data.error){
          if(data.data != null || data.data != undefined){
            this.collectionArray = data.data
            this.collection1 = this.collectionArray[0].productModels
            this.collection2 = this.collectionArray[1].productModels
            if( this.collectionArray[1].productModels != null  && Object.keys(this.collectionArray[0].productModels).length != undefined){
              if(Object.keys(this.collectionArray[0].productModels).length<this.showCount+1){
                this.rightVisibility = false;
            }
            if(Object.keys(this.collectionArray[1].productModels).length<this.showCount+1){
                this.rightVisibility2 = false;
            }
            }
          }
        }
      })


      }

      moveToLeft(event){
        
        if(this.slideLength == undefined){
          this.slideLength = event.target.previousElementSibling.getElementsByClassName("products").length
        }
        if(this.slideConst == undefined){
          this.slideConst = event.target.parentElement.getElementsByClassName("productsWrapper")[0].offsetWidth
        }
      
        this.leftVisibility = true
        this.left = this.left - this.slideConst
        this.iteration = Math.ceil(this.slideLength/this.showCount) 
        this.currIteration++
        if(this.currIteration == this.iteration){
          this.rightVisibility = false
        }
        
      }
      moveToRight(){
        this.rightVisibility = true
        this.left = this.left + this.slideConst
        this.currIteration--
        if(this.left == 0 || this.left > 0){
          this.leftVisibility = false
        }

      }


      moveToLeft2(event){
        
        if(this.slideLength2 == undefined){
          this.slideLength2 = event.target.previousElementSibling.getElementsByClassName("products").length
        }
        if(this.slideConst2 == undefined){
          this.slideConst2 = event.target.parentElement.getElementsByClassName("productsWrapper")[0].offsetWidth
        }
      
        this.leftVisibility2 = true
        this.left2 = this.left2 - this.slideConst2
        this.iteration2 = Math.ceil(this.slideLength2/this.showCount) 
        this.currIteration2++
        if(this.currIteration2 == this.iteration2){
          this.rightVisibility2 = false
        }
        
      }
      moveToRight2(){
        this.rightVisibility2 = true
        this.left2 = this.left2 + this.slideConst2
        this.currIteration2--
        if(this.left2 == 0 || this.left2 > 0){
          this.leftVisibility2 = false
        }

      }




}
